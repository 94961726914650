<template>
  <v-app>
    <v-main>
      <Caso
        v-on:responder="responder"
        v-on:volver="volver"
        v-on:buscar="buscar_archivos_subidos_empleado"
        :inputs="inputs"
        :parametrosRuta="parametrosRuta"
        :archivos="archivos"
        :datos_adjuntos="datos_adjuntos"
        :dialogo_respuesta_caso="dialogo_respuesta_caso"
        :formularios_carpeta_publica="formularios_carpeta_publica"
      />
    </v-main>
  </v-app>
</template>
<script>
import Caso from "../../components/Caso";
import acciones_casos_empleados from "../../mixins/acciones_casos_empleados";
const axios = require("axios").default;
import { EventBus } from '../../event/event-bus';
export default {
  mixins: [acciones_casos_empleados], //Esta la funcion aceptar_caso y rechazar_caso responder_caso y notificar_al_correo
  components: {
    Caso,
  },
  data() {
    return {
      parametrosRuta: {},
      inputs: [],
      window: 0,
      //panel: [0]
      archivos: [],
      //mostrar_carpeta_publica:true,
      formulario_empleado: [],
      datos_adjuntos: [], //Array que contendra un objeto {nombre, token, tipo, autor, sector}
      formularios_carpeta_publica: [],

      dialogo_respuesta_caso: false,
    };
  },
  //Esto se puede pasar a un mixin ya que se repite en revisar caso_empleado y supervisor
  created() {
    let _this = this;
    this.parametrosRuta = this.$route.params.data;
    console.log(this.parametrosRuta.data);
  
    for (
      let index = 0;
      index < this.parametrosRuta.data.datos_adjuntos.length;
      index++
    ) {
      try {
        this.datos_adjuntos.push(
          JSON.parse(this.parametrosRuta.data.datos_adjuntos[index])
        );
      } catch (error) {
        this.datos_adjuntos.push(
          this.parametrosRuta.data.datos_adjuntos[index]
        );
      }
      
    }
    console.log(this.datos_adjuntos)
  //DASDASDA
    let info 
    for (
      let index = 0;
      index < this.parametrosRuta.data.carpeta_publica.length;
      index++
    ) {
      
      try {
        info = JSON.parse(this.parametrosRuta.data.carpeta_publica[index])
        
      } catch (error) {
        info = this.parametrosRuta.data.carpeta_publica[index]
      
      }
      console.log(info)
      if(info.tipo=='Formulario'){
        this.formularios_carpeta_publica.push(info)
      }else{
        this.archivos.push(info)
      }
    }  

    Object.keys(this.parametrosRuta.data.meta_data).forEach(function (key) {
      //Quitar los _ y colocar espacios ademas de a la key de fecha limite colocar: FECHA LIMITE DEL CASO EN DIAS HABILES
      _this.inputs.push({
        input: key,
        value: _this.parametrosRuta.data.meta_data[key],
      });
    });
  },
  mounted(){
    EventBus.$on('recargarEmpleado', (payload) => {
      console.log(payload)
      this.buscar_archivos_subidos_empleado(payload.objectId)
    })
  },
  methods: {
    volver() {
      /*
      this.$router.push({
        name: "bandeja_entrada_empleado",
        params: { id: this.$route.params.id, data: this.parametrosRuta },
      });
      */
    },
    async buscar_archivos_subidos_empleado(objectId) {
      console.log("Empleado");
      try {
        this.datos_adjuntos = [];
        this.$store.commit("auth", "parse");
        let response = await axios.get(
          "https://siett.educandote.co/parse/classes/prueba01casosEmpleados/" +
            objectId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response.data);
        for (
          let index = 0;
          index < response.data.datos_adjuntos.length;
          index++
        ) {
          this.datos_adjuntos.push(
            JSON.parse(response.data.datos_adjuntos[index])
          );
        }

        this.$emit("adjuntos", this.datos_adjuntos_hijo);
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error consultando los archivos subidos intenta mas tarde"
        );
      }
    },
    async responder(tipo) {
      console.log(tipo);
      //tipo.estado puede tener 3 estados, Respondido, Rechazado, Aceptado
      try {
        this.$store.commit("auth", "parse");

        //Este endpoint es el encargado de listar los dintintos estados de los casos que se les asigne
        //Va a funcionar tambien para poder conocer graficas de los casos respondidos rechazados y aceptados
        let response = await axios.put(
          "https://siett.educandote.co/parse/classes/prueba01casosEmpleados/" +
            this.parametrosRuta.data.objectId,
          {
            comentario: tipo.comentario,
            estado: tipo.estado, //Puede cambiar a rechazado o aceptado y si se acepta se agrega
            //Los datos del empleado al endpoint de prueba01Casos al array de responsables
            //Se envia un correo al supervisor que han aceptado el caso y se envia una confirmacion
            //A un endpoint extra para avisarle al supervisor que si lo haran o no lo haran
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        console.log(response);
        let rechazado = null,
          respondido = null;
        switch (tipo.estado) {
          case "Aceptado":
            await this.aceptar_caso(tipo.comentario);
            break;
          case "Rechazado":
            rechazado = await this.rechazar_caso(tipo.comentario);
            console.log(rechazado);
            if (rechazado == true || respondido == null) {
             this.$router.go(-1)
            } else {
              console.log("Se queda en la vista");
            }
            //Si el caso es rechazado que se redireccione a la vista anterior y se actualizan los datos
            break;
          case "Respondido":
            respondido = await this.responder_caso(tipo.comentario,tipo.formulario);
            if (respondido == true || respondido == null) {
              this.$router.go(-1)
            } else {
              this.$store.commit('error','Ha ocurrido un error inesperado al enviar la respuesta')
              console.log("Se queda en la vista");
            }
            break;

          default:
            break;
        }
      } catch (error) {
        console.log(error);

        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado dando la respuesta por favor intenta mas tarde"
        );
      }

      this.dialogo_respuesta_caso = true;
      this.dialogo_respuesta_caso = false;
      this.mensaje = "";
    },
  },
};
</script>